import request from '@/utils/request'

// !获取评委模块
export function LiveJuries(params) {
  return request({
    url: '/Live/LiveJuries',
    method: 'GET',
    params
  })
}

// !参赛选手
export function Finalist(params) {
  return request({
    url: '/NewLive/Finalist',
    method: 'GET',
    params
  })
}

// !参赛作品及选手
export function LiveEntries_L3(params) {
  return request({
    url: '/Live/LiveEntries_L3',
    method: 'GET',
    params
  })
}

// !大赛日程模块
export function LiveAgendas(params) {
  return request({
    url: '/Live/LiveAgendas',
    method: 'GET',
    params
  })
}
// !获取大会banner
export function LiveBanner(params) {
  return request({
    url: '/Live/LiveBanner',
    method: 'GET',
    params
  })
}

// !获取决赛作品
export function Finalwork(params) {
  return request({
    url: '/NewLive/Finalwork',
    method: 'GET',
    params
  })
}

// !获取往届作品
export function LiveEntriesHistory(params) {
  return request({
    url: '/Live/LiveEntriesHistory',
    method: 'GET',
    params
  })
}

// !参赛作品投票
export function AddLikeEntries(data) {
  return request({
    url: '/Live/AddLikeEntries',
    method: 'POST',
    data
  })
}

// !参赛作品点赞
export function Like(data) {
  return request({
    url: '/Live/Like',
    method: 'POST',
    data
  })
}

// !生成海报
export function GetPoster(params) {
  return request({
    url: 'Personal/GetPoster',
    method: 'GET',
    params
  })
}

// !生成E3D海报
export function GetE3DPoster(params) {
  return request({
    url: 'Personal/E3DPoster',
    method: 'GET',
    params
  })
}

// !大赛报名
export function MakeLive(data) {
  return request({
    url: 'Live/MakeLive',
    method: 'POST',
    data
  })
}

// !大赛邀请好友二维码
export function InviteQRCode(params) {
  return request({
    url: 'Live/InviteQRCode',
    method: 'GET',
    params
  })
}

// !E3D邀请好友二维码
export function InviteE3DQRCode(params) {
  return request({
    url: 'E3D/InviteQRCode',
    method: 'GET',
    params
  })
}

// !获取抽奖次数
export function DrawNum(params) {
  return request({
    url: 'Draw/DrawNum',
    method: 'GET',
    params
  })
}

// !进行抽奖
export function LuckDraw(data) {
  return request({
    url: 'Draw/LuckDraw',
    method: 'POST',
    data
  })
}

// !中奖记录
export function MyLuckyDraw(params) {
  return request({
    url: 'Draw/MyLuckyDraw',
    method: 'GET',
    params
  })
}

// !问卷表单接口
export function FeedBack(data) {
  return request({
    url: 'Draw/FeedBack',
    method: 'POST',
    data
  })
}

// !获取直播回放视频
export function LivePlayBack(params) {
  return request({
    url: 'Live/LivePlayBack',
    method: 'GET',
    params
  })
}

// !记录回放日志
export function PlayBack(params) {
  return request({
    url: 'Draw/PlayBack',
    method: 'GET',
    params
  })
}

// !直播点赞功能
export function PlayLike(data) {
  return request({
    url: 'Draw/PlayLike',
    method: 'POST',
    data
  })
}

// !直播点赞数量
export function PlayLikeNum(params) {
  return request({
    url: 'Draw/PlayLikeNum',
    method: 'GET',
    params
  })
}

// !直播问卷弹出
export function FeedBackControl(data) {
  return request({
    url: 'Draw/FeedBackControl',
    method: 'POST',
    data
  })
}