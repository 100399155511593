<template>
  <div class="DrawRecord">
    <div v-if="resData.list.length" class="DrawRecord-list">
      <div class="DrawRecord-item" v-for="(item,index) in resData.list" :key="index" :class="clientWidth > 768 ? 'flex3': ''">
        <div> 
          恭喜您在活动中抽中 {{item.title}} *1
        </div>
        <div :class="clientWidth < 769 ? 'mt5 DrawRecord-item-time': ''">{{item.createTime}}</div>
      </div>
    </div>
    <div v-else class="noData">
      <img src="@/assets/PC/profile/listNone.png" alt="" />
    </div>
  </div>
</template>

<script>
import { MyLuckyDraw } from '@/api/InnovationContest.js'
import { dataState } from "@/api/all.js";
import { GetWxShares } from "@/utils/common";

import { mapState } from 'vuex'

export default {
  name: "DrawRecord",
  data() {
    return {
      resData: {
        list: [],
      },
    };
  },
  mounted () {
    const share = {
      title: '个人中心 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    this.GetInit()

    // 数据统计
		const data1 ={
			"visitType": 1,
			"gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
			"url": window.location.href,
			"pageUrl": "DrawRecord",
			"relationId": ""
		}
		dataState(data1).then(res => {
			sessionStorage.setItem('gid',res.data);
		})
  },
  methods: {
    GetInit() {
      MyLuckyDraw().then(res => {
        if(res.status === 1) {
          this.resData.list = res.data
        } else {
          this.$toast(res.message)
        }
      })
    }
  },
  computed: {
    ...mapState(['clientWidth'])
  }
};
</script>

<style lang="scss" scoped>
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .DrawRecord { 
    margin: 40px; 
    .DrawRecord-item {border: 1px #DBC3E7 solid; background: #F5EFF8; padding: 26px 33px; margin-bottom: 15px; }
  }
  @media (max-width: 768px) {
    .DrawRecord {
      margin: vw(23);
      .DrawRecord-item {padding: 10px}
      .DrawRecord-item-time {color: #666; border-top: 1px #fff solid; padding-top: 6px; }
    }
  }
</style>